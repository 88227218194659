import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { smoothHeight } from '../../animations/animations';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PostType } from 'src/app/model/SinglePost';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
   smoothHeight
  ]
})
export class CardComponent implements OnInit {

  @Input()
  title:string;

  @Input()
  subtitle:string;

  @Input()
  category:string;

  @Input()
  cardHeight:string;

  @Input()
  cardType: PostType;

  @Input()
  set expanded(val: boolean){
    this.expandedValue = val;
    if(!val && !!this.container) this.scrollToTop();

  }
  get expanded(): boolean {
    return  this.expandedValue;
  };

  expandedValue = false;

  @Input() 
  bgImage: string = "assets/coplink.jpg"; 

  @Input()
  canClose = true;

  @Output()
  close = new EventEmitter();

  constructor(private sanitizer: DomSanitizer, private container: ElementRef<HTMLDivElement>){

  }

  ngOnInit(){
    
  }

  closeCard(event:MouseEvent){
    this.close.emit();
    event.preventDefault();
    event.stopPropagation();
  }

  isVideoBanner(): boolean{
    return this.bgImage.endsWith('mp4');
  }
  getVideoUrl(): SafeResourceUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.bgImage);
  }

  public scrollToTop(){
      this.container.nativeElement.scrollTo({ top: 0 });
  }

  isBanner(){
    return this.cardType == PostType.BANNER;
  }
  isBannerTop(){
    return this.cardType == PostType.BANNER_TOP;
  }
  
}
