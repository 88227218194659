import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators'
import { EventManager } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private window: Window = window;
  private resizeSubject = new Subject<void>();

  constructor(private eventManager: EventManager){
    var myWindow: HTMLElement = document.body;
    this.eventManager.addEventListener(myWindow,'resize', () => {
      this.resizeSubject.next();
    });
    this.eventManager.addEventListener(myWindow, 'orientationchange', () => {
      this.resizeSubject.next();
    });

  }

  public onResize(): Observable<void>{
      return this.resizeSubject.asObservable();
  }

  public isMobile(): boolean {
    return  this.window.innerWidth < 600;
  }
  public isPortraitTablet(): boolean {
    return  this.window.innerWidth < 1000;
  }

}
