export interface SinglePost{
    id: number;
    title: string;
    subtitle: string;
    category: string;
    body: any[];
    bannerUrl: string;
    cardType: "banner-top" | "banner" | ""; 
}
export enum  PostType{
    BANNER_TOP = "banner-top",
    BANNER = "banner"
}