import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  stagger
} from "@angular/animations";
import myData from "../../../assets/data/data.json";
import { SinglePost } from "src/app/model/SinglePost.js";
import { CardPosition } from "src/app/model/Position.js";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { WindowService } from "src/app/services/window.service";

@Component({
  selector: "app-card-container",
  templateUrl: "./card-container.component.html",
  styleUrls: ["./card-container.component.scss"],
  animations: [
    trigger("focused", [
      state(
        "true",
        style({
          "z-index": "999"
          // "position":"fixed"
        })
      ),
      state(
        "false",
        style({
          "z-index": "997"
          // "position":"absolute"
        })
      ),
      transition("true => false", animate(".6s 0s")),
      transition("false => true", animate(0))
    ]),
    trigger("fadeInOut", [
      state(
        "true",
        style({
          opacity: "1"
        })
      ),
      state(
        "false",
        style({
          opacity: "0"
        })
      ),
      transition("true => false", animate(".4s")),
      transition("false => true", animate(".4s"))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardContainerComponent implements OnInit {
  @ViewChild("container", { static: true })
  container: ElementRef<HTMLDivElement>;

  cardHeaderHeight = "18rem";
  selectedCardHeaderHeight = "23rem";
  cardWidthPx = 300;
  cardMargin = "2.5rem";
  cardMarginPx = 30;
  containerMargin = "6rem";

  selectedId: number;

  cards: SinglePost[] = [];

  cardMap: { [id: number]: CardPosition } = {};
  backdropPosition: CardPosition;

  constructor(
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private windowService: WindowService
  ) { }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calculatePositions();
  }
  ngOnInit() {
    this.cards = myData.items as SinglePost[];
    this.calculatePositions();
    this.windowService.onResize().subscribe(() => {
      console.log("onResize");
      this.calculatePositions();
    });
  }

  ngAfterViewInit() { }

  disableScroll() {
    // Get the current page scroll position
    let scrollTop = this.container.nativeElement.scrollTop;
    let scrollLeft = this.container.nativeElement.scrollLeft;

    // if any scroll is attempted,
    // set this to the previous value
    this.container.nativeElement.onscroll = () => {
      this.container.nativeElement.scrollTo(scrollLeft, scrollTop);
    };
  }

  enableScroll() {
    this.container.nativeElement.onscroll = () => { };
  }

  isFocused(): boolean {
    return this.selectedId != null;
  }
  isSelected(id: number): boolean {
    return this.selectedId == id;
  }

  selectCard(id: number) {
    window.analytics.page(this.cards.find(card => card.id == id).title);
    this.selectedId = id;
    this.calculatePositions();
  }

  deselectCard() {
    window.analytics.track('Deselected Card');
    this.selectedId = null;
    this.calculatePositions();
  }

  getCardStyles(id: number): CardPosition {
    return this.cardMap[id];
  }

  public getScrollTop() {
    return this.container.nativeElement.scrollTop;
  }

  /*
    Layout
  */
  private calculatePositions() {
    if (this.isFocused()) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }

    if (this.isMobile()) {
      this.cardHeaderHeight = "23rem";
      this.selectedCardHeaderHeight = "26rem";
    }
    console.log(`Selected: ${this.selectedId}`);

    for (let i = 0; i < this.cards.length; i++) {
      let id = this.cards[i].id;
      this.cardMap[id] = this.isSelected(id)
        ? this.getSelectedPosition()
        : this.getPosition(i);
    }

    this.backdropPosition = this.getBackdropPosition();

    this.cdRef.detectChanges();
  }

  private getBackdropPosition(): CardPosition {
    return {
      top: `${this.getScrollTop()}px`,
      left: "0",
      width: "100vw",
      height: "100vh",
      "max-height": "100vh"
    };
  }

  private getPosition(i: number): CardPosition {
    return {
      top: this.getPositionTop(i),
      left: this.getPositionLeft(i),
      width: this.getWidth(i),
      "max-height": this.cardHeaderHeight
    };
  }

  getSelectedPosition(): CardPosition {
    let scrollHeight = this.getScrollTop();

    let tabletCardWidth = 600;

    return this.isMobile()
      ? {
        top: `${scrollHeight}px`,
        left: "0px",
        width: "100vw",
        "max-height": "100vh"
      }
      : {
        top: `calc(${scrollHeight}px + 5vh)`,
        left: `calc(50vw - ${tabletCardWidth / 2}px)`,
        width: `${tabletCardWidth}px`,
        "max-height": "95vh"
      };
  }

  private isFirstInRow(index: number): boolean {
    return index % 2 == 0;
  }

  private isWide(index: number): boolean {
    const row = this.getRow(index);
    const isEvenRow = row % 2 == 0;
    const firstInRow = this.isFirstInRow(index);
    return (isEvenRow && firstInRow) || (!isEvenRow && !firstInRow);
  }

  private getRow(index: number) {
    return Math.floor(index / 2);
  }

  private getWidth(index: number): string {
    if (this.isMobile()) {
      return `calc(100% - ${this.cardMarginPx}px)`;
    } else if (this.isPortraitTablet()) {
      return `calc(50% - ${this.cardMarginPx}px`;
    }

    return this.isWide(index) ? `${this.cardWidthPx * 2}px` : `${this.cardWidthPx}px`;
  }

  private getPositionTop(index: number): string {
    let row = 0;
    if (this.isMobile()) {
      row = index;
    } else {
      row = this.getRow(index);
    }
    return `calc(${this.containerMargin} + ${row} * ${this.cardHeaderHeight} + ${row} * ${this.cardMarginPx}px )`;
  }

  private getPositionLeft(index: number): string {
    if (this.isMobile()) {
      return `calc(${this.cardMarginPx}px / 2)`;
    } else if (this.isPortraitTablet()) {
      let startColumn = 0;
      const isFirst = this.isFirstInRow(index);

      if (isFirst) {
        startColumn = 0;
      }
      if (!isFirst) {
        startColumn = 1;
      }

      return `calc(${startColumn} * 50% + ${this.cardMarginPx}px / 2)`;
    } else {
      let marginLeft = (this.container.nativeElement.clientWidth - (this.cardWidthPx * 3 + this.cardMarginPx)) / 2;
      let startColumn = 0;
      const isFirst = this.isFirstInRow(index);
      const isWide = this.isWide(index);

      if (isFirst) {
        startColumn = 0;
      }
      if (!isFirst) {
        startColumn = 1;
      }
      if (!isFirst && !isWide) {
        startColumn = 2;
      }

      return `calc(${marginLeft}px + ${startColumn} * ${this.cardWidthPx}px + ${!isFirst ? this.cardMarginPx + "px " : '0px'
        })`;
    }
  }


  getFooterPositionTop() {
    return `calc(${this.getPositionTop(this.cards.length - 1)} + ${this.cardHeaderHeight})`;
  }

  getFooterStyle() {
    return {
      top: this.getFooterPositionTop(),
      left: "0px",

    }
  }

  /*
  Content
  */
  isVideo(url: string): boolean {
    return url.endsWith("mp4");
  }
  getSafeVideoUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /*
  Device Detection
  */
  private isMobile() {
    return this.windowService.isMobile();
  }

  private isPortraitTablet() {
    return this.windowService.isPortraitTablet();
  }
}
