<div #container class="container" [class.focused]="isFocused()">
  <div class="header">
    <div class="subtitle">Lead Developer</div>
    <div class="header-title">
      <h1>Jack Gallagher</h1>
      <div class="profile"><a href="mailto:jackgllghr@gmail.com?subject=I Want To Hire You!"><img src="assets/img/me.jpg" /></a></div>
    </div>
  </div>
  <app-card
    *ngFor="let card of cards; let i = index"
    [@focused]="isSelected(card.id)"
    [ngStyle]="getCardStyles(card.id)"
    [category]="card.category"
    [title]="card.title"
    [subtitle]="card.subtitle"
    [bgImage]="card.bannerUrl"
    [cardType]="card.cardType"
    [cardHeight]="
      isSelected(card.id) ? selectedCardHeaderHeight : cardHeaderHeight
    "
    [expanded]="isSelected(card.id)"
    (click)="selectCard(card.id)"
    [class.focused]="isSelected(card.id)"
    [canClose]="isMobile()"
    (close)="deselectCard()"
  >
    <div class="card-content" [class.show]="isSelected(card.id)">
      <ng-container *ngFor="let item of card.body">
        <div *ngIf="item.type == 'text' && item.title != null" class="text-title">{{item.title}}</div>
        <div
          *ngIf="item.type == 'text'"
          class="body-text-container"
          [class.has-title]="item.title != null"
          [innerHtml]="item.content"
        ></div>
        <div *ngIf="item.type == 'image'" class="body-image-container">
          <div class="image">
            <img [src]="item.sourceUrl" />
            <div *ngIf="item.content != ''" class="banner">
              {{ item.content }}
            </div>
          </div>
        </div>

        <div
          *ngIf="item.type == 'screenshot'"
          class="body-screenshot-container"
        >
          <div class="screenshot">
            <img *ngIf="!isVideo(item.sourceUrl)" [src]="item.sourceUrl" />
            <video
              *ngIf="isSelected(card.id) && isVideo(item.sourceUrl)"
              autoplay
              loop
              [muted]="true"
              playsinline
              onloadedmetadata="this.muted=true" oncanplay="this.play()"
            >
              <source
                [src]="getSafeVideoUrl(item.sourceUrl)"
                type="video/mp4"
              />
            </video>
            <div class="banner">{{ item.content }}</div>
          </div>
        </div>

        <div *ngIf="item.type == 'video'" class="body-video-container">
          <video
            *ngIf="isSelected(card.id)"
            [poster]="item.bannerUrl"
            controls
            preload="none"
            allowfullscreen
          >
            <source [src]="getSafeVideoUrl(item.sourceUrl)" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="item.type == 'youtube'" class="body-video-container">
          <iframe
            *ngIf="isSelected(card.id)"
            width="560"
            height="315"
            [src]="getSafeVideoUrl(item.sourceUrl)"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div *ngIf="item.type == 'summary'" class="body-summary-container">
          <img class="icon" [src]="card.iconUrl" />
          <div class="title">{{ card.title }}</div>
          <div class="content">{{ card.subtitle }}</div>
          <div *ngIf="!!card.link" class="link">
            <a target="_blank" [href]="card.link">GET</a>
          </div>
        </div>
      </ng-container>
    </div>
  </app-card>
  <div
    class="backdrop"
    [@fadeInOut]="isFocused()"
    (click)="deselectCard()"
    [class.focused]="isFocused()"
    [ngStyle]="backdropPosition"
  ></div>
  <div class="footer" [ngStyle]="getFooterStyle()">
  </div>
</div>
