<div class="container" [class.expanded]="expanded"  >
  <div class="close-button" *ngIf="expanded && canClose" (click)="closeCard($event)">
      <div class="line-1"></div>
      <div class="line-2"></div>
  </div> 
<div class="header" [style.min-height]="cardHeight" [style.background-image]="isVideoBanner() ? '' : 'url('+bgImage+')'">
    <video *ngIf="isVideoBanner()" class="banner-video" autoplay loop muted playsinline onloadedmetadata="this.muted=true" oncanplay="this.play()">
      <source [src]="getVideoUrl()" type="video/mp4">
    </video>
    <ng-container *ngIf="!isBanner() && !isBannerTop()">
      <div class="category">{{ category }}</div>
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </ng-container>
   
    <div *ngIf="isBanner() || isBannerTop()" class="banner" [class.top]="isBannerTop()" >
      <div class="category">{{ category }}</div>
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
  </div>
  <div class="content" >
    <ng-content></ng-content>
  </div>
</div>

